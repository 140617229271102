// Imports
const { render } = wp.element // <--- Wordpressify React
import { FaTwitter, FaFacebookF, FaWhatsapp } from 'react-icons/fa';

// Variables for the share button
const shareText = encodeURIComponent( "Check this out from the Taste of the West Store!" )
const shareUrl = window.location.href
const hashTags = "TasteOfTheWest,TOTW,LocalProduce,FreshProduce"
const params = "menubar=no,toolbar=no,status=no,width=570,height=570"; // for window

// Constuct our share links
const facebookUrl = `http://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareText}&${params}`
const twitterUrl =  `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}&hashtags=${hashTags}&${params}`
const whatsappUrl = `https://wa.me/?text=${shareText} Heres the link: ${shareUrl}`

const SocialShareButton = () => {
  return (
    <div>
      <a href={twitterUrl} target="_blank">
        <button className="twitterSharer">
          <FaTwitter />
        </button>
      </a>      
      <a href={facebookUrl} target="_blank">
        <button className="facebookSharer">
          <FaFacebookF />
        </button>
      </a>
      <a href={whatsappUrl} target="_blank">
        <button className="whatsappSharer">
          <FaWhatsapp />
        </button>
      </a>
    </div>
  )
  
}

// Only attempt to display our element if the correct HTML is in the page template. 
if( document.getElementById(`share-to-social-media`) ) {
  render(<SocialShareButton />, document.getElementById(`share-to-social-media`))
}